import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'app/main/settings/settings.service';


@Component({
  selector: 'app-current-conversion-rate',
  templateUrl: './current-conversion-rate.component.html',
  styleUrls: ['./current-conversion-rate.component.scss']
})
export class CurrentConversionRateComponent implements OnInit {

  latestRates: any;
  noRates: boolean = false;
  marketRateStatus: boolean;
  constructor(
    private _settingService: SettingsService
  ) { }

  ngOnInit() {
    this._settingService.getMarketRateSettings().then(() => this.marketRateStatus = this._settingService.marketRateStatusToggle);
    this._settingService.getLatestRate().then(latestRates => {
      this.latestRates = this._settingService.conversionRates;
      this.marketRateStatus = this._settingService.marketRateStatusToggle;
      console.log(this.latestRates);
      if(latestRates.length === 0) {
        this.noRates = true;
      }
    });
  }
}
