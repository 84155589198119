import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { FormGroup} from '@angular/forms';
import { OrdersOrderListService } from '../order-list.service';
import { Order } from '../../new-order/order.model';

@Component({
  selector: 'app-international-bulk-order-result',
  templateUrl: './international-bulk-order-result.component.html',
  styleUrls: ['./international-bulk-order-result.component.scss']
})
export class InternationalBulkOrderResultComponent implements OnInit, OnDestroy, AfterViewInit {

  protected _unsubscribeAll = new Subject<void>();
  // Workaround for angular component issue #13870
  disableAnimation = true;
  form: FormGroup;
  ordersImported: [];
  orders;
  constructor(
      public matDialogRef: MatDialogRef<InternationalBulkOrderResultComponent>,
      @Inject(MAT_DIALOG_DATA) private _data: any,
      private _orderListService: OrdersOrderListService
  ) {
      this.ordersImported = _data.ordersImported;
  }
  ngOnInit(): void {
      this.ordersImported

      // if (this.ordersImported.length > 0)
      this._orderListService.getOrdersForLabel(JSON.stringify(this.ordersImported)).then((result: any) => {
          this.orders = result.body as Order[];
      });
  }
  ngAfterViewInit(): void {
      // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
      setTimeout(() => (this.disableAnimation = false));
  }
  ngOnDestroy(): void {
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }
}

