import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject, fromEvent, Observable } from 'rxjs';
import { ContactsContactListService } from './contact-list.service';
import { ContactsContactListDataSource } from './contact-list.datasource';
import { MatPaginator, MatSort, MatDialogRef, MatDialog, MatTableDataSource } from '@angular/material';
import { takeUntil, debounceTime, distinctUntilChanged, finalize, last } from 'rxjs/operators';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ContactEditorComponent } from '../contact-editor/contact-editor.component';
import { FormGroup, Validators, EmailValidator, FormControl } from '@angular/forms';
import { Contact } from '../contact-form/contact.model';
import { HttpClient } from '@angular/common/http';
import { SheetJSComponent } from '../sheet-jscomponent/sheet-jscomponent.component';
import { ContactExcelListItem } from '../sheet-jscomponent/contact-list-item.model';
import { SelectionModel } from '@angular/cdk/collections';
type AOA = any[][];
import * as XLSX from 'xlsx';
import { City } from 'app/_models/city.model';
import { LocationService } from 'app/_shared/location-service.service';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { PhoneValidator } from 'app/_validators/phone.validator';
import { List } from 'lodash';
import { Country } from 'app/_models/country.model';
import { C } from '@angular/cdk/keycodes';
import { isNullOrUndefined } from 'util';
@Component({
    selector: 'contacts-contact-list',
    templateUrl: './contact-list.component.html',
    styleUrls: ['./contact-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ContactsContactListComponent implements OnInit, OnDestroy {
    dataSource: ContactsContactListDataSource | null;
    displayedColumns = ['fullName', 'companyName', 'email', 'phoneNumber', 'address', 'tags', 'actions'];

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ViewChild('filter', { static: true })
    filter: ElementRef;

    @ViewChild('FileSelectInputDialog', { static: true }) FileSelectInputDialog: ElementRef;

    contactDialogRef: MatDialogRef<ContactEditorComponent>;
    importcontactDialogRef: MatDialogRef<SheetJSComponent>;

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    successDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    selectedContact: Contact;

    countries: Country[];
    cities: City[];

    ImportContacts: Contact[];

    contacts: ContactExcelListItem[];
    excelTemplateArray: string[][] = [];
    // excelTemplateColumns: string[] = ['First Name', 'Last Name', 'Email', 'MobileCountryCode', 'Mobile', 'Country', 'City', 'Street', 'Building', 'Floor', 'Landmark', 'Postal Code'];

    // selectedCities: City[];
    data: AOA = [];
    exceldataSource = new MatTableDataSource<any>(this.data);
    selection = new SelectionModel<any>(true, []);
    wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
    importingContacts: boolean = false;
    loadingComplete: boolean = false;
    // contactType: number = 5000; //Home
    // Private


    FirstNameIndex: number; LastNameIndex: number; EmailIndex: number; CountryCodeIndex: number; MobileIndex: number; CountryIndex: number; CityIndex: number;
    StreetIndex: number; BuildingIndex: number; FloorIndex: number; LandMarkIndex: number; PostalIndex: number; CompanyNameIndex: number


    switchingType: boolean
    private _unsubscribeAll: Subject<any>;
    excel = [];
    constructor(private _contactsContactListService: ContactsContactListService,
        public _matDialog: MatDialog, private _locationService: LocationService,
        private _contactService: ContactsContactListService) {
        this._unsubscribeAll = new Subject();
    }
    ngOnInit(): void {
        this.dataSource = new ContactsContactListDataSource(
            this._contactsContactListService,
            this.paginator,
            this.sort,
        );
        // this.dataSource.setTypeFilter(this.contactType);

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                // this.dataSource.filter = this.filter.nativeElement.value;
                if (this.paginator.pageIndex == 0)
                    this.paginator.pageIndex = this.paginator.pageIndex + 1;

                this._contactsContactListService.getContacts(this.paginator, this.filter.nativeElement.value).then((response: any) => {
                    const paginationHeaders = response.headers.get('Pagination');
                    this.paginator.pageIndex = 0;
                    this.dataSource = new ContactsContactListDataSource(
                        this._contactsContactListService,
                        this.paginator,
                        this.sort,
                    );
                    this.dataSource.filter = this.filter.nativeElement.value;

                    this.switchingType = false;
                });
            });
        this.getCountries();
        this.getCities();
    }
    private getCountries(): Promise<any> {
        this.loadingComplete = false;
        return new Promise((resolve, reject) => {
            this._locationService.getCountriesFromDB().subscribe(success => {
                resolve(success);
                this.countries = success;
            });
        });
    }
    private getCities(): Promise<any> {
        this.loadingComplete = false;
        return new Promise((resolve, reject) => {
            this._locationService.getCitiesFromDB().subscribe(success => {
                resolve(success);
                this.cities = success;

                this.loadingComplete = true;
            });
        });
    }
    openContactDialog(action: string): void {
        this.contactDialogRef = this._matDialog.open(ContactEditorComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                action: action,
                contact: this.selectedContact,
                countries: this.countries
            }
        });

        this.contactDialogRef.afterClosed().subscribe(response => {
            if (!response) {
                return;
            }
            const status = response[0] as string;
            if (status === 'cancel') {
                return;
            }
            const contact = response[1] as Contact;
            const tags = response[2] as string[];

            contact.tags = tags;
            if (contact.contactId === 0 && status === 'new') {
                this._contactsContactListService.addNewContact(contact);
            } else if (contact.contactId !== 0 && status === 'edit') {
                this._contactsContactListService.editContact(contact);
            }
        });
    }

    public OpenAddFilesDialog(evt: any) {
        const e: HTMLElement = this.FileSelectInputDialog.nativeElement;
        e.click();
    }

    onFileChange(evt: any) {
        this.importingContacts = true;
        /* wire up file reader */
        this.contacts = [];
        this.exceldataSource = new MatTableDataSource<any>(this.contacts);
        const target: DataTransfer = <DataTransfer>(evt.target);
        if (target.files.length !== 1) throw new Error('Cannot use multiple files');
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
            this.data = this.data.filter((entry) => { return entry.length != 0 })

            if (this.verifyExcelTemplate(this.data)) {
                this.generateExcelDatatable();
            }
            else
                this.openInvalidExcelDialog()

        };


        reader.readAsBinaryString(target.files[0]);
        evt.target.value = '';
    }
    assignExcelIndexes(data): void {
        this.FirstNameIndex = data[0].indexOf("First Name");
        this.LastNameIndex = data[0].indexOf("Last Name");
        this.CompanyNameIndex = data[0].indexOf("Company Name");
        this.EmailIndex = data[0].indexOf("Email");
        this.CountryCodeIndex = data[0].indexOf("MobileCountryCode");
        this.MobileIndex = data[0].indexOf("Mobile");
        this.CountryIndex = data[0].indexOf("Country");
        this.CityIndex = data[0].indexOf("City");
        this.StreetIndex = data[0].indexOf("Street");
        this.BuildingIndex = data[0].indexOf("Building");
        this.FloorIndex = data[0].indexOf("Floor");
        this.LandMarkIndex = data[0].indexOf("Landmark");
        this.PostalIndex = data[0].indexOf("Postal Code");
    }
    verifyExcelTemplate(data): boolean {
        this.assignExcelIndexes(data);
        if (data.length > 0) {
            if (this.FirstNameIndex == -1 || this.LastNameIndex == -1 || this.CountryCodeIndex == -1 || this.MobileIndex == -1
                || this.CountryIndex == -1 || this.CityIndex == -1 || this.StreetIndex == -1) {
                return false
            }
            else
                return true;
        }
        else {
            return false
        }
    }

    private openInvalidExcelDialog(): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.header = 'Invalid Excel Format imported';
        this.confirmDialogRef.componentInstance.confirm = 'Template';
        this.confirmDialogRef.componentInstance.cancel = 'Cancel';

        this.confirmDialogRef.componentInstance.confirmMessage =
            'Please note that the excel imported is invalid. Kindly use the template!';
        this.confirmDialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult)
                this.exportExcel('MyNet Contact Import Template.xlsx');
        });
    }
    private openSuccessExcelDialog(count): void {
        this.successDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this.successDialogRef.componentInstance.header = 'Success';
        this.successDialogRef.componentInstance.confirm = 'Ok';
        this.successDialogRef.componentInstance.cancel = '';
        this.successDialogRef.componentInstance.confirmMessage =
            count + ' contacts imported!';
    }
    onValChange(typeSelected) {
        this.switchingType = true;
        this._contactsContactListService.typeId = typeSelected;
        if (this.paginator.pageIndex == 0)
            this.paginator.pageIndex = this.paginator.pageIndex + 1;
        this._contactsContactListService.getContacts(this.paginator).then((response: any) => {
            this.filter.nativeElement.value = "";
            // this.dataSource.filter = "";
            this.paginator.pageIndex = 0;
            this.dataSource = new ContactsContactListDataSource(
                this._contactsContactListService,
                this.paginator,
                this.sort,
            );
            this.dataSource.filter = this.filter.nativeElement.value;
            this.switchingType = false;
        });
    }

    exportExcel(filename): void {
        let link = document.createElement("a");
        link.download = filename;
        link.href = "assets/MyNet Contact Import Template.xlsx";
        link.click();
    }

    openImportDialog(): void {
        this.importcontactDialogRef = this._matDialog.open(SheetJSComponent
            , {
                panelClass: 'contact-form-dialog',
                data: {
                    contacts: this.contacts,
                    countries: this.countries,
                    cities: this.cities
                }
            }
        );
        this.importcontactDialogRef.afterClosed().subscribe(response => {
            this.switchingType  = true;

            this.ImportContacts = [];
            if (!response) {
                return;
            }
            const status = response[0] as string;
            if (status === 'cancel') {
                this.importingContacts = false;
                return;
            }
            const contacts = response[1] as Contact[];


            this._contactsContactListService.addImportContacts(contacts).then(() => {
                this.openSuccessExcelDialog(contacts.length);
                this.dataSource = new ContactsContactListDataSource(
                    this._contactsContactListService,
                    this.paginator,
                    this.sort
                );
             this.switchingType = false;
            });
            this.importcontactDialogRef = null;
        }
        );
    }
    generateExcelDatatable() {
        this.contacts = [];
        let id = 0;


        this.data.slice(1, this.data.length).forEach(contact => {
            let c = new ContactExcelListItem();

            c.isValid = true;
            c.importId = id;
            c.firstName = contact[this.FirstNameIndex] || '';
            c.lastName = contact[this.LastNameIndex];
            c.email = contact[this.EmailIndex];

            c.phoneCountryCode = contact[this.CountryCodeIndex];
            if (c.phoneCountryCode == undefined)
                c.phoneCountryCode = "+961";
            if (c.phoneCountryCode.toString().indexOf("+") == -1)
                c.phoneCountryCode = "+" + c.phoneCountryCode;


            c.companyName = contact[this.CompanyNameIndex];
            if (isNullOrUndefined(c.companyName))
                c.companyName = "";
            c.phoneNumber = contact[this.MobileIndex];
            c.addresses[0].countryName = contact[this.CountryIndex];
            c.addresses[0].cityName = contact[this.CityIndex];
            c.addresses[0].street = contact[this.StreetIndex];
            c.addresses[0].building = contact[this.BuildingIndex];
            c.addresses[0].floor = contact[this.FloorIndex];
            c.addresses[0].landmark = contact[this.LandMarkIndex];
            c.addresses[0].zipCode = contact[this.PostalIndex];
            c = this.validateExcel(c);
            this.contacts.push(c);

            id += 1;
        });
        this.openImportDialog();
    }



    public validateExcel(contact: ContactExcelListItem): ContactExcelListItem {
        const contactAddress = contact.addresses[0];
        if (contactAddress.cityName == '' || contactAddress.countryName == '' || contactAddress.street == undefined || contactAddress.building == '')
            contact.isValid = false;
        if (contact.firstName == '' || contact.lastName == '')
            contact.isValid = false;
        var countryIdRetreived = this.countries.find(x => x.name == contact.addresses[0].countryName);
        if (!isNullOrUndefined(countryIdRetreived)) {
            contact.addresses[0].countryId = countryIdRetreived.countryId;
            // contact.addresses[0].cities = this.cities.filter(x => x.countryId == countryIdRetreived.countryId);
        }
        else {
            countryIdRetreived = this.countries.find(x => x.countryId == 158);
            contact.addresses[0].countryId = 158;
            // contact.addresses[0].cities = this.cities.filter(x => x.countryId == 158);
        }
        var cityIdRetreived = this.cities.find(x => x.cityName == contact.addresses[0].cityName);
        if (!isNullOrUndefined(cityIdRetreived)) {
            contact.addresses[0].cityId = cityIdRetreived.cityId;
        }
        else
            contact.isValid = false;
        const mobile = contact.phoneNumber;

        const validatedPhone = PhoneValidator.ValidPhone(mobile.toString().replace("-", ""), countryIdRetreived.countryReference);
        if (validatedPhone != '')
            contact.phoneNumber = validatedPhone
        else {
            contact.phoneNumber = '';
            contact.isValid = false;
        }

        return contact;
    }
    emailIsValid(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
    editContact(contactId: number): void {
        this._contactsContactListService.getContact(contactId).then((contact: Contact) => {
            this.selectedContact = contact;
            this.openContactDialog('edit');
        });
    }

    deleteContact(contactId: number): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        const contact = this._contactsContactListService.contacts.find(c => c.contactId === contactId);
        this.confirmDialogRef.componentInstance.header = 'Delete Contact';
        this.confirmDialogRef.componentInstance.confirm = 'Yes';
        this.confirmDialogRef.componentInstance.cancel = 'No';
        this.confirmDialogRef.componentInstance.confirmMessage =
            'Are you sure you want to delete "' + contact.fullName + '"?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._contactsContactListService.deleteContact(contactId);
                this.dataSource = new ContactsContactListDataSource(
                    this._contactsContactListService,
                    this.paginator,
                    this.sort
                );
            }
            this.confirmDialogRef = null;
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    pagination(pageEvents): void {
        pageEvents.pageIndex = pageEvents.pageIndex + 1;
        this._contactsContactListService.getContacts(pageEvents, this.filter.nativeElement.value);
    }

}
