<div class="dialog-content-wrapper" fxFlex style="flex:30%">

  <input #FileSelectInputDialog type="file" style="visibility: hidden" multiple="false"
      (change)="onFileChange($event , FileSelectInputDialog.files)">

  <mat-toolbar matDialogTitle class="mat-accent m-0">
      <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
          <span class="title dialog-title">
              Bulk Orders
          </span>
          <!-- <button mat-icon-button tabindex="-1" (click)="matDialogRef.close()" aria-label="Close dialog">
              <mat-icon>close</mat-icon>
          </button> -->
      </mat-toolbar-row>
  </mat-toolbar>

  <mat-dialog-content>
      <form [formGroup]="BulkOrderForm">
          <div class="pt-16 pb-16 w-100-p">
              <div class="row">
                  <mat-form-field class="col-xs-12 col-md-12">
                      <mat-label>
                          How soon do you want it received?
                      </mat-label>
                      <mat-select formControlName="shippingTypeId" name="mynet-order-shippingTypeId"
                          (selectionChange)="onChange($event)" required>
                          <mat-option *ngFor="let shippingType of shippingTypes" [value]="shippingType.id">
                              {{shippingType.description}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <!-- <div class="row">
                  <mat-form-field class="col-xs-12 col-md-12">
                      <mat-label>What are you shipping?</mat-label>
                      <mat-select name="mynet-order-commodity" formControlName="commodityId" required>
                          <mat-option *ngFor="let commodity of commodities" [value]="commodity.commodityId">
                              <span class="text-truncate">{{ commodity.title }}</span>
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="row">
                  <mat-form-field class="col-xs-12 col-md-12">
                      <mat-label>Full description of goods</mat-label>
                      <textarea matInput rows="3" formControlName="goodsDescription"
                          name="mynet-order-goodsDescription"></textarea>
                  </mat-form-field>
              </div> -->
              <div class="date row">
                  <mat-form-field class="col-xs-12 col-md-6">
                      <mat-label>Pickup date</mat-label>
                      <input matInput name="mynet-order-pickupdate" placeholder="Date" [min]="minDate"
                          [matDatepicker]="picker" (focus)="picker.open()" readonly required
                          formControlName="collectionDate">
                      <mat-icon matSuffix>date_range</mat-icon>
                      <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                  <mat-radio-group class="mb-16" name="mynet-order-timerange-type" formControlName="timeRangeLookupId"
                      required>
                      <div *ngFor="let range of timeRanges; let idx = index">
                          <mat-radio-button [value]="range.id">{{range.name}}
                          </mat-radio-button>
                      </div>
                      <mat-error *ngIf="BulkOrderForm.get('timeRangeLookupId').hasError('required')">
                          Please provide a pickup time.
                      </mat-error>
                  </mat-radio-group>
              </div>
              <div>
                  <b *ngIf="storerKey !== 'null' && this.bulkSettings === 'Old'" style="color:red">For SKUs, add them in excel following this example: SKUName(Requested_Amount),SKUName(Requested_Amount)</b>
                  <b *ngIf="storerKey !== 'null' && this.bulkSettings === 'New'" style="color:red">For SKUs, put the SKU under the SKU column and the quantity under the SKU Quantity column<br>
                      In this case, the Shipper Reference is required<br>All orders with the same shipper reference will be counted as one order with their respective SKUs</b>
              </div>

              <!-- <div class="row">
                  <mat-form-field class="pl-4 no-padding-sm col-xs-12 col-md-12">
                      <mat-label>Special Instructions</mat-label>
                      <textarea matInput rows="3" formControlName="specialInstructions"
                          name="mynet-order-specialInstructions"></textarea>
                  </mat-form-field>
              </div> -->
              <!-- class="row" -->

              <div class="row" *ngIf="totalOrders">
                  <h3 *ngIf="bulkOrdersImported.length != totalOrders" style="font-weight: bolder;"> Please wait!
                      Processing Orders :
                      <b style="color: red;">{{bulkOrdersImported.length}}</b> /
                      <b>{{ totalOrders}}</b>
                      <mat-icon *ngIf="bulkOrdersImported.length == totalOrders"
                          style="color: green;margin-left: 10px">check
                      </mat-icon>
                  </h3>
                  <h3 *ngIf="bulkOrdersImported.length == totalOrders" style="font-weight: bolder;">Processed Orders :
                      <b style="color: red;">{{bulkOrdersImported.length}}</b> /
                      <b>{{ totalOrders}}</b>
                      <mat-icon *ngIf="bulkOrdersImported.length == totalOrders"
                          style="color: green;margin-left: 10px">check
                      </mat-icon>
                  </h3>
              </div>
              <!-- <div class="row" *ngIf="importingBulk">
                  <h3 *ngIf="importingBulk" style="font-weight: bolder;">Generating Labels ...
                  </h3>
              </div> -->

              <mat-progress-bar *ngIf="bulkOrdersImported.length != totalOrders || contacts == undefined"
                  mode="indeterminate">
              </mat-progress-bar>
              <mat-progress-bar *ngIf="loadingOrders" mode="indeterminate"></mat-progress-bar>


          </div>
      </form>
  </mat-dialog-content>

  <mat-dialog-actions class="m-0 p-24 dialog-footer" fxLayout="row" fxLayoutAlign="end center" align="end">
      <button mat-raised-button color="primary" (click)="matDialogRef.close(['cancel'])"
          [disabled]="bulkOrdersImported.length != totalOrders" aria-label="Back" matTooltip="Back to Orders">
          Back
      </button>
      <button mat-raised-button color="primary"
          [disabled]="bulkOrdersImported.length != totalOrders || totalOrders == 0 " (click)="printCheckedLabels()"
          aria-label="Print" matTooltip="Print All">
          Print {{bulkOrdersImported.length === 0 ? "" : "(" + bulkOrdersImported.length + ")"}}
      </button>
      <!-- class="save-button" -->
      <button mat-raised-button color="primary" (click)="exportExcel()" matTooltip="Download Excel Template">Download
          {{selectedDirection}} Template</button>
      <button mat-raised-button color="primary" (click)="import()" aria-label="Confirm"
          [disabled]="bulkOrdersImported.length != totalOrders || contacts == undefined"
          matTooltip="Download Excel Sheet">
          Import Excel
      </button>
  </mat-dialog-actions>
</div>