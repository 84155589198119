import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { OrderService } from 'app/main/orders/order.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BillingDetails } from 'app/main/orders/new-order/order.model';
import * as XLSX from 'xlsx';
@Component({
    selector: 'order-manifest',
    templateUrl: './order-manifest.component.html',
    styleUrls: ['./order-manifest.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class OrderManifestComponent implements OnInit, OnDestroy {
    fromDate: Date = new Date();
    toDate: Date = new Date();

    displayedColumns = ['label', 'shipper', 'origin', 'receiver', 'destination', 'weight', 'nop'];
    dataSource;
    ELEMENT_DATA: OrderManifest[] = [];

    fromDateFormatted;
    toDateFormatted;
    minDate  : Date = new Date();


    @ViewChild('table', { static: false }) table: ElementRef;

    orders: OrderManifest[] = [];
    billingDetails: BillingDetails = new BillingDetails();
    showProgressBar: boolean = false;

    group: FormGroup;
    // Private
    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {InvoiceService} _invoiceService
     */
    constructor(private _formBuilder: FormBuilder, private _orderService: OrderService) {

        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.fromDate.setHours(0, 0, 0);

        this.toDate.setHours(23, 59, 0);

        this._orderService.getMyInfo().subscribe((info: BillingDetails) => {
            this.billingDetails = info;
        });
        this.group = this._formBuilder.group({
            fromDate: ['', [Validators.required]],
            toDate: ['', [Validators.required]],
        });
        this.group.get('fromDate').setValue(this.fromDate);
        this.fromDateFormatted = this.fromDate.toJSON().slice(0, 10).replace(/-/g, '/');

        this.group.get('toDate').setValue(this.toDate);
        this.toDateFormatted = this.toDate.toJSON().slice(0, 10).replace(/-/g, '/');
        this.group.get('fromDate').valueChanges.subscribe(value => {
            this.showProgressBar = true;
            // this.fromDate = value;
            this.fromDateFormatted = new Date(value.getTime() - (value.getTimezoneOffset() * 60000)).toJSON().slice(0, 10).replace(/-/g, '/');

            this._orderService.getOrderManifest(value, this.toDateFormatted).subscribe(orders => {
                this.ELEMENT_DATA = [];


                this.orders = orders;
                this.orders.forEach(x => {
                    this.ELEMENT_DATA.push(x);
                });
                this.dataSource = this.ELEMENT_DATA;
                this.showProgressBar = false;
                // this.minDate = value;
            });
        });
        this.group.get('toDate').valueChanges.subscribe(value => {
            this.showProgressBar = true;
            // this.toDate = value;
            this.toDateFormatted = new Date(value.getTime() - (value.getTimezoneOffset() * 60000)).toJSON().slice(0, 10).replace(/-/g, '/');

            this._orderService.getOrderManifest(this.fromDateFormatted, value).subscribe(orders => {
                this.ELEMENT_DATA = [];
                this.orders = orders;
                this.orders.forEach(x => {
                    this.ELEMENT_DATA.push(x);
                });
                this.dataSource = this.ELEMENT_DATA;
                this.showProgressBar = false;
            });
        });

        this._orderService.getOrderManifest(this.fromDate, this.toDate).subscribe(orders => {
            this.ELEMENT_DATA = [];
            this.fromDateFormatted = this.fromDate.toJSON().slice(0, 10).replace(/-/g, '/');
            this.orders = orders;
            this.orders.forEach(x => {
                this.ELEMENT_DATA.push(x);
            });
            this.dataSource = this.ELEMENT_DATA;
            this.showProgressBar = false;

        });


    }
    ngAfterViewInit(): void {
    }
    onPrint() {
        window.print();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    exportAsExcel() {

        const ws = XLSX.utils.json_to_sheet(this.dataSource);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        var sheetName = 'Manifest --' + this.fromDate.toJSON().slice(0, 10).replace(/-/g, '/') + ' - ' + this.toDate.toJSON().slice(0, 10).replace(/-/g, '/') + '.xlsx'
        /* save to file */
        XLSX.writeFile(wb, sheetName);

    }



}
export class OrderManifest {
    Label: string;
    Shipper: string;
    Origin: string;
    Receiver: string;
    Destination: string;
    Type: string;
    Weight: number;
    Nop: number;
    ShipmentValue: number
    constructor(order?) {
        order = order || {};
        this.Label = order.Label;
        this.Shipper = order.Shipper;
        this.Origin = order.Origin;
        this.Receiver = order.Receiver || '';
        this.Destination = order.Destination;
        this.Type = order.Type;
        this.Weight = order.Weight;
        this.Nop = order.Nop;
        this.ShipmentValue = order.ShipmentValue;
    }
}






