import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { QuotesModule } from 'app/main/quotes/quotes.module';
// import { SocialLoginModule } from 'angularx-social-login';
// import { AuthServiceConfig, GoogleLoginProvider } from 'angularx-social-login';
import { AuthGuard } from './_guards/auth.guard';
import { AuthenticationModule } from './authentication/authentication.module';
import { UnauthorizedInterceptor } from './_interceptors/unauthorized.interceptor';
import { JwtModule } from '@auth0/angular-jwt';
import { ContactsModule } from './main/contacts/contacts.module';
import { ContactsListModule } from './main/c-list/c-list.module';
import { ComingSoonModule } from './main/coming-soon/coming-soon.module';
import { ContactFormModule } from './main/contacts/contact-form/contact-form.module';
import { ContactListFormModule } from './main/c-list/c-list-form/c-list-form.module';
import { OrdersModule } from './main/orders/orders.module';
import { AutoCompleteModule } from './_shared/auto-complete/auto-complete.module';
import { HeardAboutUsDialogModule } from './main/profile/heard-about-us-dialog/heard-about-us-dialog.module';
import { MatCheckboxModule, MatSelectModule, MatFormFieldModule, MatProgressBarModule } from '@angular/material';
import { FormsModule } from '@angular/forms';

// import { NgxBarcodeModule } from 'ngx-barcode';
import { DistributionModule } from './main/distributions/distributions.module';
import { ProfileModule } from './main/profile/profile.module';
import { AutoCompleteLocationModule } from './_shared/auto-complete-location/auto-complete-location.module';
import { AgmCoreModule } from '@agm/core';
import { ReportModule } from './main/reports/report.module';
import { InvoicePaymentModule } from './main/Invoice-payment/invoice-payment.module';
import { NetPointModule } from './main/netpoint-netlocker/netpoint.module';
import { OrderCodSharedComponent } from './_shared/order-cod-shared/order-cod-shared.component';
import { OrderCodSharedModule } from './_shared/order-cod-shared/order-cod-shared.module';
import { OrderSkuSharedModule } from './_shared/order-sku-shared/order-sku-shared.module';
import { OrderSkuSharedComponent } from './_shared/order-sku-shared/order-sku-shared.component';
import { SettingsModule } from './main/settings/settings.module';
import { CurrentConversionRateComponent } from './main/profile/current-conversion-rate/current-conversion-rate.component';
import { CurrentConversionRateModule } from './main/profile/current-conversion-rate/current-conversion-rate.module';
// import { DistributionModule } from './main/distributions/distributions.module';
// import { DistributionModule } from './main/Distribution/Distribution-list.module';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


// const config = new AuthServiceConfig([
//     {
//         id: GoogleLoginProvider.PROVIDER_ID,
//         provider: new GoogleLoginProvider('596842775209-qu63quttt5u9tinektcfql36palkilt7.apps.googleusercontent.com')
//     }

    
    //,
    // {
    //     id: FacebookLoginProvider.PROVIDER_ID,
    //     provider: new FacebookLoginProvider('1222180297906249')
    // }
// ]);

export function tokenGetter(): string {
    return localStorage.getItem('token');
}

// export function provideConfig(): AuthServiceConfig {
//     return config;
// }

const appRoutes: Routes = [
    {
        path: '**',
        redirectTo: 'quotes/quick'
    },
    
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
        // NgxPowerBiModule,
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        QuotesModule,
        // SocialLoginModule,
        AuthenticationModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                whitelistedDomains: ['localhost:5001', 'my.thenet.group', 'https://4de7725e510c.ngrok.io'],
                blacklistedRoutes: []
            }
        }),
        // ProfileModule,
        ContactsModule,
        ContactsListModule,
        ComingSoonModule,
        InvoicePaymentModule,
        NetPointModule,
        ContactFormModule,
        ContactListFormModule,
        OrdersModule,
        ProfileModule,
        SettingsModule,
        ReportModule,
        DistributionModule,
        AutoCompleteModule,
        AutoCompleteLocationModule,
        HeardAboutUsDialogModule,
        MatCheckboxModule,
        MatSelectModule,
        FormsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyA55Si7IDxBu2aBY4u2_yF6RJJvJGSNvd0'
            // please get your own API key here:
            // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
        }),
        OrderCodSharedModule,
        OrderSkuSharedModule,
        MatButtonModule,
        CurrentConversionRateModule

        // NgxMatSelectSearchModule
    ],
    bootstrap: [AppComponent],
    exports:[OrderCodSharedComponent, OrderSkuSharedComponent, CurrentConversionRateComponent],
    providers: [
        // AuthGuard,
        // {
        //     provide: AuthServiceConfig,
        //     useFactory: provideConfig
        // },
        { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true }
    ]
})
export class AppModule { }
